import React from "react";
import { Preview } from "./Preview";
import { AboutMe } from "./AboutMe";
import lovelLocksGif from "./images/lovelocks-gif.gif";
import passtimeImg1 from "./images/passtime1.jpg";
import passtimeImg2 from "./images/passtime2.png";
import passtimeV2Img1 from "./images/passtimev2-1.png";
import passtimeV2Img2 from "./images/passtimev2-2.png";
import passtimeV2ImgGif from "./images/passtimev2-gif.gif";
import twitchImg1 from "./images/twitch1.jpg";
import twitchImg2 from "./images/twitch2.gif";
import twitchImg3 from "./images/twitch3.jpg";
import twitchImg4 from "./images/twitch4.jpg";
import twitchImg5 from "./images/twitch5.jpg";
import twitchImg6 from "./images/twitch6.jpg";
import bedroomJammerImg2 from "./images/bedroom-jammer2.jpg";
import bedroomJammerImg1 from "./images/bedroom-jammer1.jpg";
import bedroomJammerGif from "./images/bedroom-jammer-gif.gif";
import emojiGif from "./images/emoji-gif.gif";
import glovePhoneImg1 from "./images/glove-phone1.jpg";
import glovePhoneImg2 from "./images/glove-phone2.jpg";
import glovePhoneImg3 from "./images/glove-phone3.jpg";
import lab100Img1 from "./images/lab-1.jpg";
import lab100Img2 from "./images/lab-2.jpg";
import lab100Img3 from "./images/lab-3.jpg";
import lab100Img4 from "./images/lab-4.jpg";
import lab100Img5 from "./images/lab-5.jpg";
import colorFactoryImg1 from "./images/color-1.jpg";
import colorFactoryImg2 from "./images/color-2.jpg";
import colorFactoryImg3 from "./images/color-3.jpg";
import colorFactoryImg4 from "./images/color-4.jpg";
import colorFactoryImg5 from "./images/color-5.jpg";
import aboutMeDescription from "./aboutme-description";
export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      about: {
        title: "✨🚀",
        description: aboutMeDescription
      },
      projects: {
        passtimeV2: {
          title: "Passtime",
          shortName: "passtimeV2",
          shortDescription: "open-ended video chat world",
          description: `Passtime enables you to connect and play with friends via live video chat in an open-ended world.
          Walk around and explore while video calling. Jump from world to world via portals, cultivate and customize limitless rooms
          with GIFs, stickers, videos, or your own images. Create personalized spaces for events like weddings, music and art shows, 
          galleries, parties, or recreate any physical space to share with friends.`,
          tech: [
            "WebRTC",
            "Google Cloud"
          ],
          role: ["Front-end", "Back-end", "Concept", "Product"],
          sites: [
            { url: "https://passtime.world", isAlive: true },
          ],
          media: [
            {
              type: "image",
              url: passtimeV2ImgGif
            },
            {
              type: "image",
              url: passtimeV2Img2
            },
            {
              type: "image",
              url: passtimeV2Img1
            },
          ],
          press: [
            {
              title: "Media Monks Labs Report",
              url:
                "https://docs.google.com/presentation/d/e/2PACX-1vSOKFTNtXoPq62GSLkclQyi1yTNxETdW1y-GJMvx1u66GokhG_1wrnJdNtc554IlmF0sNmRtL9LVxVs/pub?start=false&loop=false&delayms=3000&slide=id.g8636e12cfb_0_294"
            }
          ]
        },
        lab100: {
          title: "Lab100",
          shortName: "lab100",
          shortDescription: "medical lab of the future",
          description: `As lead front-end developer at Cactus, I worked in close collaboration with Mount Sinai to develop
             a new type of medical space called Lab100, a hybrid clinic and research lab. I was in charge of 
             building two custom apps for the two key user types. Doctors control everything about the clinic 
             through a unified “control app” built native on an android tablet. Patients have their own platform-agnostic 
             web-app where scheduling, payment, educational videos and interactive results all appear seamlessly on demand.`,
          tech: [
            "Node.js",
            "Websockets",
            "React.js",
            "Javascript",
            "Unity",
            "Oculus",
            "Raspberry Pi"
          ],
          role: ["Front-end", "Hardware Implementation", "Unity"],
          sites: [
            { url: "https://lab100.org", isAlive: true },
            { url: "https://app.lab100.org", isAlive: true }
          ],
          media: [
            {
              type: "image",
              url: lab100Img1
            },
            {
              type: "image",
              url: lab100Img2
            },
            {
              type: "image",
              url: lab100Img3
            },
            {
              type: "image",
              url: lab100Img4
            },
            {
              type: "image",
              url: lab100Img5
            }
          ],
          press: [
            {
              title: "designweek.co.uk",
              url:
                "https://www.designweek.co.uk/issues/2-8-july-2018/the-ai-powered-doctors-clinic-that-could-warn-patients-before-they-get-sick"
            },
            {
              title: "businessinsider.com",
              url:
                "https://www.businessinsider.com/inside-mount-sinais-lab100s-clinic-of-the-future-2018-8"
            },
            {
              title: "core77.com",
              url:
                "https://www.core77.com/posts/77952/A-Clinic-and-Research-Lab-Designed-to-Make-the-Patient-Experience-Pleasant-and-Comprehensible"
            }
          ]
        },
        colorfactory: {
          title: "Color Factory",
          shortName: "colorfactory",
          shortDescription: "interactive exhibit",
          description: `The Color Factory is a collaborative interactive exhibit in a series of 16 rooms 
          with unique experiential art in each. I worked on the digital registration, backend database, automated photography and CMS systems 
          that make the Color Factory run. Once each guest is registered, they are given a card with an 
          individualized QR code. As they visit each sequential room, they can scan their card to capture high-quality, 
          perfectly-framed, perfectly-lit images and animated gifs without having to rely on their phones.`,
          tech: [
            "React.js",
            "React Native",
            "Raspberry Pi",
            "Node.js",
            "AWS Lambda"
          ],
          role: ["Front-end", "Hardware Implementation"],
          sites: [{ url: "http://colorfactory.co", isAlive: true }],
          media: [
            { type: "image", url: colorFactoryImg1 },
            { type: "image", url: colorFactoryImg2 },
            { type: "image", url: colorFactoryImg3 },
            { type: "image", url: colorFactoryImg4 },
            { type: "image", url: colorFactoryImg5 }
          ],
          press: [{ title: "", url: "" }]
        },
        svrround: {
          title: "SVRROUND",
          shortName: "svrround",
          shortDescription: "AR/VR livestreaming platform",
          description: `I am a co-founder and chief product officer of SVRROUND, an AR/VR enterprise platform to easily create and 
            share 360˚ live streams. It combines rich interactivity on the viewers end with unique analytics on the 
            streamers end to best utilize the 360˚medium. SVRROUND went through the 
            <a href="http://nycmedialab.org/combine/" target="_blank">NYC Media Lab Combine Accelerator</a> and 
            was funded by <a href="http://www.roughdraft.vc/" target="_blank">Rough Draft Ventures</a>, 
            <a href="http://nycmedialab.org/" target="_blank"> NYC Media Lab</a>, and
             <a href="http://innovation.verizon.com/content/vic/en/innovation-labs.html/Verizon" 
            target="_blank">Verizon Innovation Labs</a>. I led front-end development, back-end architecture, 
            and API design. Also the analytics dashboard with 3D graphics and 360° video implementation.`,
          tech: [
            "Node.js",
            "Websockets",
            "Three.js",
            "Javascript",
            "Express.js",
            "MongoDB",
            "Handlebars",
            "Gulp",
            "Pug/Jade",
            "SASS"
          ],
          role: [
            "Front-end",
            "Back-end",
            "Visual Design",
            "Concept",
            "Founder",
            "Product",
            "Business"
          ],
          sites: [{ url: "https://svrround.com", isAlive: false }],
          media: [
            {
              type: "youtube",
              url: "https://youtube.com/embed/POa-hH04fXA?rel=0&hd=1"
            }
          ],
          press: [{ title: "", url: "" }]
        },
        immigrater: {
          title: "Immigrater",
          shortDescription: "interactive billboard at Times Square",
          shortName: "immigrater",
          description:
            'An interactive billboard located at the Viacom building in Times Square that allowed anyone to swipe to accept or reject immigrants, tinder style. The project critiqued the banale nature of the US attitude towards immigration. <br/><br/> Immigrater was built for <a href="http://viacom.com" target="_blank">Viacom</a> and <a href="http://nycmedialab.org">NYC Media Lab</a>',
          tech: [
            "Node.js",
            "Websockets",
            "Javascript",
            "HTML",
            "CSS",
            "Express.js"
          ],
          role: ["Front-end", "Back-end", "Concept"],
          sites: [{ url: "https://immigrater.us", isAlive: false }],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/apGaoW5eLO4"
            }
          ],
          press: [{ title: "", url: "" }]
        },
        passtime: {
          title: "Passtime Chrome Extension",
          shortName: "passtime",
          shortDescription: "browse with avatars",
          description:
            'A chrome extension that puts avatars of friends at the bottom of web browsers so friends can watch videos, listen to songs, and read articles together more easily.<br/><br/> Passtime was built for my thesis at <a href="https://tisch.nyu.edu/itp" target="_blank">NYU ITP</a> with support from <a href="https://research.google.com/" target="_blank">Research at Google</a>.',
          tech: [
            "Node.js",
            "Websockets",
            "Three.js",
            "Javascript",
            "HTML",
            "CSS",
            "Gulp",
            "Express.js",
            "Handlebars"
          ],
          role: ["Front-end", "Back-end", "Design", "Concept", "Product"],
          sites: [{ url: "https://passti.me", isAlive: false }],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/VtrBiOfD4wY"
            },
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/dTa4V8IRT_E"
            },
            { type: "image", url: passtimeImg1 },
            { type: "image", url: passtimeImg2 }
          ],
          press: [{ title: "", url: "" }]
        },
        lovelocks: {
          title: "Crypto LoveLocks",
          shortName: "lovelocks",
          shortDescription: "conceptual blockchain application (dApp)",
          description:
            "Crypto LoveLocks takes the idea of the love lock and puts it on the blockchain so they can never be taken down again. Using a smart contract, 50,000 love locks are available to customize and engrave with a message to be kept on the Ethereum blockchain forever.",
          tech: ["Solidity", "Truffle", "React.js", "Webpack", "Blockchain"],
          role: ["Front-end", "Back-end", "Visual Design", "Concept"],
          sites: [{ url: "http://cryptolovelocks.co", isAlive: false }],
          media: [{ type: "image", url: lovelLocksGif }],
          press: [{ title: "", url: "" }]
        },
        twitch: {
          title: "Twitch Plays Shakespeare",
          shortName: "twitch",
          shortDescription: "interactive theatre application",
          description:
            'Inspired by the popular Twitch Plays Pokemon, in this interactive take on Shakespeare’s Hamlet, an in-person audience votes continuously on Hamlet’s actions throughout the course of the play. </br></br> Twitch Plays Shakespeare was built with support from <a href="https://research.google.com/" target="_blank">Research at Google</a>',
          tech: [
            "Node.js",
            "Websockets",
            "Javascript",
            "HTML",
            "CSS",
            "Express.js"
          ],
          role: ["Front-end", "Back-end"],
          sites: [{ url: "twitchplays.com" }],
          media: [
            { type: "image", url: twitchImg2 },
            { type: "image", url: twitchImg1 },
            { type: "image", url: twitchImg3 },
            { type: "image", url: twitchImg4 },
            { type: "image", url: twitchImg5 },
            { type: "image", url: twitchImg6 }
          ],
          press: [{ title: "", url: "" }]
        },

        canale: {
          title: "Canale Quattro",
          shortName: "canale",
          shortDescription: "music broadcast application",
          description:
            'Live broadcast websites for the premiere of the band Phoenix\'s single "J-Boy". Viewers watched together around the world with an immersive, functional, retro television.',
          tech: ["WebGL", "Javascript", "HTML", "CSS"],
          role: ["Front-end", "Back-end", "Visual Design", "Sound Design"],
          sites: [{ url: "https://canalequattro.tv", isAlive: false }],
          media: [
            { type: "youtube", url: "https://youtube.com/embed/q6EjzpLOwoo" }
          ],
          press: [{ title: "", url: "" }]
        },

        magic: {
          title: "Magic is Real",
          shortName: "magic",
          shortDescription: "interactive music experience/performance",
          description:
            "A performance piece showcasing a 7 episode interactive music app that animates a story of a girl who finds many pieces. <br/><br/> This recording was performed at SXSW 2016.",
          tech: [
            "Node.js",
            "Websockets",
            "Javascript",
            "Touchdesigner",
            "Python",
            "HTML",
            "CSS"
          ],
          role: [
            "Front-end",
            "Back-end",
            "Design",
            "Concept",
            "Story",
            "Music",
            "Sound Design",
            "Performance"
          ],
          sites: [],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/8ki_Bk6EPcw"
            }
          ],
          press: [{ title: "", url: "" }]
        },

        glitch: {
          title: "Glitch the World",
          shortName: "glitch",
          shortDescription: "interactive cinema experience",
          description:
            'An in-person interactive storytelling experience about a young girl trying to break out of a digital world, losing her sense of reality along the way. <br/><br/> The audience joined a mobile web app that was timed with the main story to chat with the characters in the story, get additional sounds, animation, and story, play games to help push the narrative forward, and explore a world map during intermission.<br/><br/> Glitch the World was built with support from <a href="https://research.google.com/" target="_blank">Research at Google</a>.',
          tech: [
            "Touchdesigner",
            "Websockets",
            "Python",
            "Node.js",
            "Javascript",
            "HTML",
            "CSS"
          ],
          role: ["Front-end", "Back-end", "Design", "Story", "Concept"],
          sites: [],
          media: [
            { type: "youtube", url: "https://youtube.com/embed/7HcznestTHI" }
          ],
          press: [{ title: "", url: "" }]
        },

        bedroom: {
          title: "Bedroom Jammer",
          shortName: "bedroom",
          shortDescription: "interactive music game",
          description:
            "An interactive music web app where each user that connects gets 1 of 4 unique characters to interact with the environment to create music together.",
          tech: [
            "Node.js",
            "Websockets",
            "Phaser.js",
            "Javascript",
            "HTML",
            "CSS",
            "Express.js"
          ],
          role: [
            "Front-end",
            "Back-end",
            "Visual Design",
            "Concept",
            "Sound Design"
          ],
          sites: [{ url: "http://bedroomjammer.com", isAlive: false }],
          media: [
            { type: "image", url: bedroomJammerImg2 },
            { type: "image", url: bedroomJammerImg1 },
            { type: "image", url: bedroomJammerGif }
          ],
          press: [{ title: "", url: "" }]
        },

        emoji: {
          title: "Emoji Dance",
          shortName: "emoji",
          shortDescription: "interactive music application",
          description:
            "A websites to discover and save your favorite Japanese Emoji all while watch. The Emoji join the party and dance along to the song.",
          tech: ["Javascript", "HTML", "CSS"],
          role: ["Front-end", "Design", "Concept"],
          sites: [{ url: "http://emoji.magicisreal.net", alive: false }],
          media: [{ type: "image", url: emojiGif }],
          press: [{ title: "", url: "" }]
        },

        dimensions: {
          title: "Dimensions",
          shortName: "dimensions",
          shortDescription: "interactive installation",
          description:
            "An installation where fog is projection mapped to recreate the look and feel of our galaxy. Users can join a mobile web app and leave their own colored stars presented with fiber optic cables and can even control the speed and direction of the fog of stars.",
          tech: [
            "Node.js",
            "Websockets",
            "Javascript",
            "HTML",
            "CSS",
            "Arduino",
            "Three.js"
          ],
          role: ["Front-end", "Fabrication", "Sound Design", "Design"],
          sites: [],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/JDO-xQSmC8A"
            }
          ],
          press: [{ title: "", url: "" }]
        },

        haptec: {
          title: "Haptec",
          shortName: "haptec",
          shortDescription: "bike navigation hardware",
          description:
            "An integrated system for bicycle navigation which allows a cyclist to intuitively feel their orientation to magnetic North, or a destination-beacon,  with vibration motors in the handles and an LED module to optimize bike journey safety, efficiency and freedom.",
          tech: [
            "Node.js",
            "Websockets",
            "Javascript",
            "HTML",
            "CSS",
            "Express.js",
            "Arduino",
            "Circuitry",
            "Cordova"
          ],
          role: [
            "Front-end",
            "Back-end",
            "App Development",
            "Fabrication",
            "Design",
            "Concept",
            "Sensors",
            "Product",
            "Business"
          ],
          sites: [{ url: "https://haptec.io", isAlive: false }],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/MspImczjQ5Q"
            }
          ],
          press: [{ title: "", url: "" }]
        },

        glove: {
          title: "Glove Phone",
          shortName: "glove",
          shortDescription: "GSM connected glove",
          description:
            "This gestural phone was built right into a glove that could be worn. Speed dial your favorites by tapping your thumb to your index, middle or ring finger. To end a call tap your thumb to the top of your pinky. You also get haptic notification when you receive a call. To answer just tap your thumb to your pinky.",
          tech: ["Arduino", "Circuitry", "Fona Board"],
          role: ["Code", "Fabrication", "Design", "Concept", "Sensors"],
          sites: [],
          media: [
            {
              type: "youtube",
              url: "https://www.youtube.com/embed/Fj5yJmoLfZg"
            },
            { type: "image", url: glovePhoneImg1 },
            { type: "image", url: glovePhoneImg2 },
            { type: "image", url: glovePhoneImg3 }
          ],
          press: [{ title: "", url: "" }]
        }
        // ,
        // space : {
        //   title:'Space Girl',
        //   shortName:'space',
        //   description:'An interactive music piece and video game about a girl getting ready for school by flying through space.',
        //   tech:[ 'Javascript', 'HTML', 'CSS'],
        //   role:['Front-end','Design','Concept'],
        //   sites:{},
        //   media:[{type:'',url:''}],
        //   press:[{title:'',url:''}]
        // }
      }
    };

    this.onAboutMeClick = this.onAboutMeClick.bind(this);
    this.closeAboutMe = this.closeAboutMe.bind(this);
  }

  onAboutMeClick() {
    this.setState({ isAboutMeActive: true });
  }

  closeAboutMe(e) {
    this.setState({ isAboutMeActive: null });
  }

  render() {
    const { projects, about } = this.state;

    console.log({ projects });

    return (
      <div>
        <div className="row">
          {Object.keys(projects).map((item, index) => {
            console.log({ item });
            return (
              <Preview
                projects={projects}
                title={projects[item].title}
                shortDescription={projects[item].shortDescription}
                shortName={projects[item].shortName}
                key={index}
              />
            );
          })}
        </div>
        <div onClick={this.onAboutMeClick} id="about-me-btn">
          <span>?</span>
        </div>
        {this.state.isAboutMeActive ? (
          <AboutMe about={about} closeAboutMe={this.closeAboutMe} />
        ) : null}
      </div>
    );
  }
}
