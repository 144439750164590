import React from "react";
import Portal from "react-minimalist-portal";
import ReactHtmlParser from "react-html-parser";
import { Carousel } from "./Carousel.js";
import $ from "jquery";

export class Project extends React.Component {
  constructor(props) {
    super(props);

    const { project } = this.props;

    console.log({ project });

    this.state = {
      media: project.media
    };
    this.onCloseClick = this.onCloseClick.bind(this);
  }

  onCloseClick(e) {
    e.preventDefault();
    const { closeProject } = this.props;
    closeProject(e);
  }

  componentDidMount() {
    $("html, body, #react-app").addClass("hideScoll");
  }

  componentWillUnmount() {
    $("html, body, #react-app").removeClass("hideScoll");
  }

  render() {
    const { project } = this.props;

    return (
      <Portal>
        <div
          key="overlay"
          className="modal-overlay"
          onClick={this.onCloseClick}
        >
          <div className="modal fadeIn" onClick={e => e.stopPropagation()}>
            <h1 className="project-title project-title-desktop">
              {project.title}{" "}
              <a href="#" onClick={this.onCloseClick} className="close-modal">
                <span>×</span>
              </a>
            </h1>
            <div className="container-fluid">
              <div className="row project-content">
                <h1 className="project-title project-title-mobile">
                  {project.title}{" "}
                  <a
                    href="#"
                    onClick={this.onCloseClick}
                    className="close-modal"
                  >
                    <span>×</span>
                  </a>
                </h1>
                <div className="details-col col-xs-12 col-md-6 col-lg-6 col-xl-6">
                  <br />
                  <p>{ReactHtmlParser(project.description)}</p>
                  <br />
                  {project.sites.length > 0 && (
                    <React.Fragment>
                      <h3>Project Link</h3>
                      <br />
                      {project.sites.map((item, index) => {
                        return item.isAlive ? (
                          <React.Fragment>
                            {index > 0 && ` & `}
                            <a className="link" href={item.url} target="_blank">
                              {item.url}
                            </a>
                          </React.Fragment>
                        ) : (
                          <div className="dead-link link">{item.url}</div>
                        );
                      })}
                      <br />
                    </React.Fragment>
                  )}

                  <h3>Roles</h3>
                  <br />
                  {project.role.map((item, index) => {
                    return (
                      <span key={index} className="tag role">
                        {item}
                      </span>
                    );
                  })}
                  <br />
                  <h3>Tech</h3>
                  <br />
                  {project.tech.map((item, index) => {
                    return (
                      <span key={index} className="tag tech">
                        {item}
                      </span>
                    );
                  })}
                </div>
                <div className="media-col col-xs-12 col-md-6 col-lg-6 col-xl-6">
                  <Carousel className="carousel" media={this.state.media} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}
