import React from "react";
import Portal from "react-minimalist-portal";
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";

export class AboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseClick = this.onCloseClick.bind(this);
  }

  onCloseClick(e) {
    e.preventDefault();
    const { closeAboutMe } = this.props;
    closeAboutMe();
  }

  componentDidMount() {
    $("html, body, #react-app").addClass("hideScoll");
  }

  componentWillUnmount() {
    $("html, body, #react-app").removeClass("hideScoll");
  }

  render() {
    const { about } = this.props;

    return (
      <Portal>
        <div
          key="overlay"
          className="modal-overlay"
          onClick={this.props.closeAboutMe}
        >
          <div className="modal fadeIn" onClick={e => e.stopPropagation()}>
            <h1 className="project-title about-title project-title-desktop">
              {about.title}{" "}
              <a href="#" onClick={this.onCloseClick} className="close-modal">
                <span>×</span>
              </a>
            </h1>
            <div className="container-fluid about-content">
              <div className="row">
                <h1 className="project-title about-title project-title-mobile">
                  {about.title}{" "}
                  <a
                    href="#"
                    onClick={this.onCloseClick}
                    className="close-modal"
                  >
                    <span>×</span>
                  </a>
                </h1>
                <div className="about-body col-xs-12">
                  <p>{ReactHtmlParser(about.description)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}
