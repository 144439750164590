import resumePdf from "./images/resume.pdf";

const description = `
Hey I’m Marc!
<br/><br/>
A full-stack developer, educator, entrepreneur and in general, a creator.
I love connecting people live in new ways and building scalable realtime applications.
Check out my <a href=${resumePdf} target="_blank">resume</a> for more information about my experience.
<br/><br/>
I am available for front-end (Typescript, ESNext, React, Redux), full-stack (front-end + node, express, SQL/noSQL, AWS),
decentralized app development (truffle, solidity, IPFS), as a creative technologist (arduino, raspberry pi) or teaching (web fundamentals).
<br/><br/>
Some of my work has featured at SXSW, Times Square, Microsoft Hub, AOL Virtual Reality Summit, Creative Tech Week, NY IFP Media Center,
IAC Media Center, NYC Media Lab Summit, NYU Production Lab, and more.
<br/><br/>
`;

export default description;
